import React, { FC, useEffect, useRef, useState } from 'react'
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade'
import Link from 'next/link'
import cx from 'classnames'
import { createPortal } from 'react-dom'

import ArrowIcon from '../../../public/static/icons/arrow-left.svg'
import CartIcon from '../../../public/static/icons/cart.svg'
import css from './AsideModal.module.scss'
import { useRouter } from 'next/router'
import cn from 'classnames'
import createContainer from '../../../lib/createContainer'

interface AsideModal {
  open: boolean
  top?: JSX.Element
  className?: string
  onClose: () => void
}

const AsideModal: FC<AsideModal> = ({
  open,
  top,
  children,
  className,
  onClose,
}) => {
  const ref = useRef<HTMLDialogElement>()
  const router = useRouter()
  const [show, setShow] = useState(false)
  const [container, setContainer] = useState(null)

  useEffect(() => {
    clearAllBodyScrollLocks()
    onClose()
  }, [router.asPath])

  useEffect(() => {
    if (!window && !document) {
      return
    }

    if (open) {
      setShow(true)
      setTimeout(() => {
        disableBodyScroll(ref.current)
      }, 100)
    } else {
      if (show) {
        clearAllBodyScrollLocks()
        setTimeout(() => {
          setShow(false)
        }, 100)
      }
    }
  }, [open])

  useEffect(() => {
    setContainer(createContainer({ id: 'asideModalContainer' }))
  }, [])

  if (container === null) return null

  return createPortal(
    <>
      <div
        className={cn(css.overlay, show && css['overlay--open'])}
        onClick={onClose}
      />
      <dialog
        open
        ref={ref}
        className={cn(css.content, show && css['content--open'])}
      >
        <div className={cx(css.wrapper, className)}>
          {top || (
            <div className={css.buttons}>
              <button type="button" onClick={onClose}>
                <ArrowIcon />
              </button>

              <Link href="/" passHref>
                <a>
                  <CartIcon />
                </a>
              </Link>
            </div>
          )}
          <div className={css.children}>{children}</div>
        </div>
      </dialog>
    </>,
    container,
  )
}

export default AsideModal
